import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import './App.scss';
import { fabClasses } from '@mui/material';
import ButtonBar from './views/button/button_bar/ButtonBar';
import { ToastContainer, toast } from "react-toastify";

function App() {
    // https://stackoverflow.com/questions/454202/creating-a-textarea-with-auto-resize
    document.querySelectorAll("textarea").forEach(function(textarea) {
      textarea.style.height = textarea.scrollHeight + "px";
      textarea.style.overflowY = "hidden";
    
      textarea.addEventListener("input", function() {
        this.style.height = "auto";
        this.style.height = this.scrollHeight + "px";
      });
    });

    const [text, setText] = useState("");
    const [num_words, setNumWords] = useState(0);
    const [field, setField] = useState("");
    const [tool, setTool] = useState("");
    const [toolMode, setToolMode] = useState("[Auto]");
    const [tool_name, setToolName] = useState("Begin Your Search");
    const [tone, setTone] = useState("");
    const [toneMode, setToneMode] = useState("Auto");
    const [keywords, setKeywords] = useState([]);
    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const [generatedLink, setGeneratedLink] = useState("");
    const [is_timed_updated, setIsTimedUpdated] = useState(false);
    const [debouncedText, setDebouncedText] = useState(text);
    const [isBusy, setIsBusy] = useState(false);
    const [shouldExecute, setShouldExecute] = useState(false);
    const searchFormatCheck = (str) => {
      const regex = /^\[.*\]$/;
      return regex.test(str);
    }

    const [showKeywords, setShowKeywords] = useState(false);

    const toggleKeywords = () => {
      setShowKeywords(!showKeywords);
    };
    
    // Monitor for state change and execute if conditions are met
    useEffect(() => {
      if (!isBusy && shouldExecute) {
        generateLink();
        setShouldExecute(false); // Reset the execute flag
      }
    }, [isBusy, shouldExecute]);

    const full_update = () => {
        if (text) {
          const words = text.trim().split(/\s+/);
          const num = words.filter(word => word !== "").length;
          if (num >= 1) { // when number of word is changed
            // if (num < 2) {
            //   setField("");
            // }
            console.log("new operation");
            identifyField();
            if (toneMode === "Auto") identifyTone();
            if (toolMode == "[Auto]") identifyTool();
            generateKeywords();
          }
      }
    }



    useEffect(() => {
      if (toolMode == "[Auto]") {
        if (text) {
          identifyTool();
        }
      } else {
        setTool(toolMode);
      }
    }, [toolMode]);

    useEffect(() => { //[Google], [Amazon], [Google Scholar], [JSTOR], [eBay], [ChatGPT]
      setIsBusy(true);
      if (searchFormatCheck(tool)) {
        setToolName(`${tool.substring(1, tool.length - 1)}`);
      } else {
        setToolName("Begin Your Search");
      }
      console.log("CALL KEY: tool changed");
      generateKeywords();
    }, [tool]);

    //typing updates
    useEffect(() => {
      setIsBusy(true);
      console.log("text changed");
      if (text) {
        const handler = setTimeout(() => {
          setDebouncedText(text);
        }, showKeywords == true ? 500 : 1000); // 1 second delay
        // Clear the timeout if text changes again before 1 second
        return () => clearTimeout(handler);
      } else {
        setField("");
        setKeywords([]);
      }
    }, [text]);

    useEffect(() => {
      if (text) {
        const words = text.trim().split(/\s+/);
        const num = words.filter(word => word !== "").length;
        // console.log(num);
        full_update();
        setNumWords(num);
      } else {
          setField("");
          setKeywords([]);
      }
    }, [debouncedText]);

    useEffect(() => {
    }, [num_words]);


    //change in tone or tools
    useEffect(() => {
      setIsBusy(true);
      generateKeywords();
    }, [tone]);

    useEffect(() => {
      if (toneMode == 'Auto') {
        identifyTone();
      } else {
        setTone(toneMode);
      }
    }, [toneMode]);

    const identifyField = async () => {
        console.log(process.env.REACT_APP_FIND_FIELD);

        const res = await axios.post(process.env.REACT_APP_FIND_FIELD, { text });
        setField(res.data.field);
    };

    const identifyTool = async () => {
        const res = await axios.post(process.env.REACT_APP_FIND_TOOL, { text, field, tone });
        setTool(res.data.tool);
    };

    const identifyTone = async () => {
        const res = await axios.post(process.env.REACT_APP_FIND_TONE, { text });
        setTone(res.data.tone);
    };

    const generateKeywords = async () => {

        // if (field == '') return;
        if (tool == '') {
          return;
        }

        console.log("generateKeywords 3");
        const res = await axios.post(process.env.REACT_APP_GEN_KEYWORDS, { text, field, tool});
        setSelectedKeywords(res.data.keywords);
        setKeywords(res.data.keywords);
        setIsBusy(false);
    };

    const handleKeywordClick = (keyword) => {
        setSelectedKeywords(prev => 
            prev.includes(keyword) ? prev.filter(k => k !== keyword) : [...prev, keyword]
        );
    };

    const generateLink = async () => {
        if (!text) return;
        if (keywords.length == 0) full_update();
        const res = await axios.post(process.env.REACT_APP_GEN_LINK, { keywords: selectedKeywords, tool, tone });
        setGeneratedLink(res.data.link);
        const newWindow = window.open(res.data.link, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null; // Extra security measure
    };

    const handleSearchClick = () => {
      if (!text) {
        alert("Please enter some keywords to search!");
        return;
      }
      if (!isBusy) {
        generateLink();
      } else {
        setShouldExecute(true);
        setToolName("Analyzing..."); // Set flag to execute once isBusy becomes false
      }
    };

    const buttonList = [
      {
        icon: '/icons/search_icon.svg',
        text: tool_name,
        onClick: () => handleSearchClick(),
        classNames: 'custom-wide-button-main-group'
      },
      // {
      //   icon: '/icons/album_icon.svg',
      //   text: '',
      //   onClick: () => console.log('Album clicked'),
      //   classNames: 'custom-round-button'
      // },
    ];
  
    const menuList = [
      {
        icon: '/icons/back_icon.svg',
        text: 'Auto',
        onClick: () => setToolMode("[Auto]")
      },
      {
        icon: '/icons/search_icon.svg',
        text: 'Google',
        onClick: () => setToolMode("[Google]")
      },
      {
        icon: '/icons/search_icon.svg',
        text: 'Google Scholar',
        onClick: () => setToolMode("[Google Scholar]")
      },
      {
        icon: '/icons/search_icon.svg',
        text: 'Amazon',
        onClick: () => setToolMode("[Amazon]")
      },
      {
        icon: '/icons/search_icon.svg',
        text: 'eBay',
        onClick: () => setToolMode("[eBay]")
      },
      {
        icon: '/icons/search_icon.svg',
        text: 'JSTOR',
        onClick: () => setToolMode("[JSTOR]")
      }
    ];

    return (
      <>
        <div className="App">
          <ToastContainer />
            <div className="container">
              <h1 className = "custom-font-title-0 hm-title">Hemlock Mini</h1>
              <div className="tab-slider custom-clickable-small">
                <div className="tabs">
                  <div
                    className={`tab ${toneMode === 'Auto' ? 'active' : ''}`}
                    onClick={() => setToneMode("Auto")}
                  >
                    Auto
                  </div>
                  <div
                    className={`tab ${toneMode === 'Casual' ? 'active' : ''}`}
                    onClick={() => setToneMode("Casual")}
                  >
                    Casual
                  </div>
                  <div
                    className={`tab ${toneMode === 'Scholar' ? 'active' : ''}`}
                    onClick={() => setToneMode('Scholar')}
                  >
                    Scholar
                  </div>
                  <div
                    className="slider"
                    style={{
                      transform: `translateX(${toneMode === 'Auto' ? '0%' : toneMode === 'Casual' ? '100%' : '200%'})`,
                    }}
                  />
                </div>
              </div>

            <div className='hm-input-keywords-container'>
              <div className='hm-input-keywords-container-inner'>
              <div className='hm-input-containers'> 
                <textarea 
                      className='hm-input-ui'
                      value={text}
                      rows={1} 
                      onChange={(e) => setText(e.target.value)}
                      placeholder="Type something here..."
                  />
              </div>
              <div className='hm-keywords-containers'>
                
                  {showKeywords && (
                    <div className='hm-keywords-containers-content'>
                    <div className="keywords-container">
                        <div className="keywords-container-content">
                        
                        {keywords.map((keyword, index) => (
                            <div key={index} className={`hm-keyword-shadow ${selectedKeywords.includes(keyword) ? "selected" : "not-selected"}`} onClick={() => handleKeywordClick(keyword)}>
                                <span> {keyword} </span>
                            </div>
                        ))}
                        </div>
                    </div>
                    </div>
                  )}
                

                <div className='hm-keywords-containers-banner'>
                  <div className="field-display">{field}</div>
                  <div className="custom-font-ui-2 hm-ai-gradient-font custom-clickable truncate-single-line" onClick={toggleKeywords}>
                    {keywords.length > 0 ? `${showKeywords ? 'Hide' : 'Show'} Keywords` : ' '}
                  </div>
                </div>
                
              </div>
              </div>
            </div>
                
                {/* <button onClick={generateLink} className="custom-wide-button-main">{tool_name}</button> */}
                <ButtonBar buttonList={buttonList} menuList={menuList} />
                
                {/* {generatedLink && (
                    <p>Generated Link: <a href={generatedLink} target="_blank" rel="noopener noreferrer">{generatedLink}</a></p>
                )} */}

                <div className='cutom-font-note'>Doelenia ©2025 - Hemlock Mini Beta v0.1.0</div>
            </div>
        </div>
        </>
    );
}

export default App;
