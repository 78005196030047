import React, { useState, useRef, useEffect } from 'react';
import './ButtonBar.scss';

const ButtonBar = ({ buttonList, menuList, direction = 'up' }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [animationClass, setAnimationClass] = useState('');
  const menuRef = useRef(null);

  const handleMoreClick = () => {
    console.log(showMenu);
    if (showMenu) {
      setAnimationClass('hide');
      setTimeout(() => {
        console.log('hide');
        setShowMenu(false);
      }, 300);
    } else {
      console.log('show');
      setShowMenu(true);
      setAnimationClass('show');
    }
  };

  const handleOutsideClick = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setAnimationClass('hide');
      setTimeout(() => {
        console.log('hide');
        setShowMenu(false);
      }, 300);
    }
  };

  useEffect(() => {
    if (showMenu) {
      document.addEventListener('mouseup', handleOutsideClick);
    } else {
      document.removeEventListener('mouseup', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mouseup', handleOutsideClick);
    };
  }, [showMenu]);

  return (
    <div className="custom-button-bar">
      {buttonList.map((button, index) => (
        <button
          key={index}
          className={`${button.classNames}`}
          onClick={button.onClick}
        >
          <img src={button.icon} alt="icon" className="custom-button-icon" />
          {button.classNames.includes('wide') && button.text && <span className="button-text">{button.text}</span>}
        </button>
      ))}
      {menuList.length > 0 && (
        <div className="more-button-container">
          {showMenu && (
            <div ref={menuRef} className={`dropdown-menu${direction === 'up' ? '-up' : ''} ${animationClass}`}>
              {menuList.map((item, index) => (
                <button key={index} onClick={item.onClick} className="dropdown-item">
                  <img src={item.icon} alt="icon" className="custom-button-icon" />
                  <span className="custom-font-ui-1">{item.text}</span>
                </button>
              ))}
            </div>
          )}
          <button className="custom-round-button" onClick={handleMoreClick}>
						<img src="/icons/more_icon.svg" alt="more" className="custom-button-icon" />
          </button>
          
        </div>
      )}
    </div>
  );
};

export default ButtonBar;